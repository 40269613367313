import {RaceBaseModel} from './race-base-model';
import {TrailerModel} from './trailer.model';
import {TerrainModel} from './terrain.model';

/**
 * Created by macbookaxel on 10/06/2016.
 */
export class RaceOffModel extends RaceBaseModel {
  id: number;
  trailer: TrailerModel;
  terrain: TerrainModel;

  public constructor(init?: Partial<RaceOffModel>) {
    super(init);
  }

  public hydrate(init?: Partial<RaceOffModel>) {
    Object.assign(this, init);
  }
}
