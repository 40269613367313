import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {SearchService} from '../../../services/common/search.service';
import {SnackbarService} from '../../../services/notifications/snackbar.service';
import {DatetimeService} from '../../../services/common/datetime.service';
import {catchError, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {Moment} from 'moment';

@Component({
  selector: 'app-search-box-mobile',
  templateUrl: './search-box-mobile.component.html',
  styleUrls: ['./search-box-mobile.component.scss']
})
export class SearchBoxMobileComponent implements OnInit, AfterViewInit {

  searchRaceForm: UntypedFormGroup;

  range: UntypedFormGroup;

  nameCtrl: UntypedFormControl;
  locationCtrl: UntypedFormControl;
  startCtrl: UntypedFormControl;
  dateSearchStartCtrl: UntypedFormControl;
  endCtrl: UntypedFormControl;
  dateSearchEndCtrl: UntypedFormControl;
  latCtrl: UntypedFormControl;
  lonCtrl: UntypedFormControl;
  localityCtrl: UntypedFormControl;
  postalCodeCtrl: UntypedFormControl;
  countryCtrl: UntypedFormControl;
  administrativeAreaLevel2Ctrl: UntypedFormControl;
  administrativeAreaLevel1Ctrl: UntypedFormControl;

  today: Date = new Date();

  @ViewChild('autocompleteLocation') autocompleteLocation: ElementRef;
  @Output() closeSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

  private scope: string[] = ['event'];

  constructor(fb: UntypedFormBuilder,
              private router: Router) {

    this.nameCtrl = new UntypedFormControl('');
    this.locationCtrl = new UntypedFormControl('');
    this.startCtrl = new UntypedFormControl('');
    this.dateSearchStartCtrl = new UntypedFormControl('');
    this.endCtrl = new UntypedFormControl('');
    this.dateSearchEndCtrl = new UntypedFormControl('');
    this.latCtrl = new UntypedFormControl('');
    this.lonCtrl = new UntypedFormControl('');
    this.localityCtrl = new UntypedFormControl('');
    this.postalCodeCtrl = new UntypedFormControl('');
    this.countryCtrl = new UntypedFormControl('');
    this.administrativeAreaLevel2Ctrl = new UntypedFormControl('');
    this.administrativeAreaLevel1Ctrl = new UntypedFormControl('');

    this.range = new UntypedFormGroup({
      start: this.startCtrl,
      end: this.endCtrl
    });

    this.searchRaceForm = fb.group({
      name: this.nameCtrl,
      location: this.locationCtrl,
      latitude: this.latCtrl,
      longitude: this.lonCtrl,
      locality: this.localityCtrl,
      postalCode: this.postalCodeCtrl,
      country: this.countryCtrl,
      administrativeAreaLevel2: this.administrativeAreaLevel2Ctrl,
      administrativeAreaLevel1: this.administrativeAreaLevel1Ctrl,
      dateSearchStart: this.dateSearchStartCtrl,
      dateSearchEnd: this.dateSearchEndCtrl
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.handleAutocompleteLocation();
  }

  handleAutocompleteLocation() {

    const componentForm = {
      locality: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
      administrative_area_level_2: 'short_name',
      administrative_area_level_1: 'short_name'
    };

    const options = {
      // componentRestrictions: {country: 'fr'}
    };

    // const input: HTMLInputElement = this.autocompleteLocation.nativeElement as HTMLElement;

    const autocomplete = new google.maps.places.Autocomplete(this.autocompleteLocation.nativeElement, options);

    google.maps.event.addListener(autocomplete, 'place_changed', () => {

      const place = autocomplete.getPlace();

      if (place) {

        this.resetLocationCtrl();

        for (const placeElement of place.address_components) {
          const addressType = placeElement.types[0];
          if (componentForm[addressType]) {
            const val = placeElement[componentForm[addressType]];
            switch (addressType) {
              case 'locality':
                this.localityCtrl.setValue(val);
                break;
              case 'country':
                this.countryCtrl.setValue(val);
                break;
              case 'postal_code':
                this.postalCodeCtrl.setValue(val);
                break;
              case 'administrative_area_level_2':
                this.administrativeAreaLevel2Ctrl.setValue(val);
                break;
              case 'administrative_area_level_1':
                this.administrativeAreaLevel1Ctrl.setValue(val);
                break;
            }
          }
        }
        if (place.geometry) {
          this.latCtrl.setValue(place.geometry.location.lat());
          this.lonCtrl.setValue(place.geometry.location.lng());
        }
      }
    });
  }

  searchRace(): void {
    this.closeSearch.emit(true);
    this.router.navigate(['events'], {queryParams: this.searchRaceForm.value});
  }

  resetLocationCtrl() {
    this.localityCtrl.reset();
    this.countryCtrl.reset();
    this.postalCodeCtrl.reset();
    this.administrativeAreaLevel2Ctrl.reset();
    this.administrativeAreaLevel1Ctrl.reset();
  }


}
