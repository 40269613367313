<mat-dialog-content class="poi-dialog-content">
  <div class="content-wrap">
    <div class="no-gutters _contain_poi">
      <div class="col-12 p-0 d-flex" id="pois-datas-wrap">

        <div class="_left_subsection d-flex flex-wrap p-3 col-12 flex-fill">
          <div class="subsection col-12 px-3">
            <span class="_since">Depuis le départ</span>
            <div class="d-flex justify-content-between subsection-content align-items-center">
              <div class="text-center">
                <i class="icon-splits_distance splits-icon-md"></i>
                <span>{{ workingPoi?.distance |number:'1.1-1'}} km</span>
              </div>
              <div class="text-center">
                <i class="icon-splits_elevation splits-icon-md"></i>
                <span>+ {{ workingPoi?.cumulativePositiveElevation |number:'1.0-0'}} mD+</span>
              </div>
              <div class="text-center">
                <i class="icon-splits_elevation splits-icon-md"></i>
                <span>- {{ workingPoi?.cumulativeNegativeElevation |number:'1.0-0'}} mD-</span>
              </div>
            </div>
          </div>
          <div *ngIf="workingPoi?.previousPoiName" class="col-12 subsection">
            <span class="_since">Depuis {{ workingPoi?.previousPoiName }}</span>
            <div class="d-flex justify-content-between subsection-content align-items-center">
              <div class="text-center">
                <i class="icon-splits_distance splits-icon-md"></i>
                <span>{{ workingPoi?.distanceInBetween|number:'1.1-1'}} km</span>
              </div>
              <div class="text-center">
                <i class="icon-splits_elevation splits-icon-md"></i>
                <span>+ {{ workingPoi?.cumulativePositiveElevationInBetween |number:'1.0-0' }} mD+</span>
              </div>
              <div class="text-center">
                <i class="icon-splits_elevation splits-icon-md"></i>
                <span>- {{ workingPoi?.cumulativeNegativeElevationInBetween |number: '1.0-0' }} mD-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex _right_subsection p-3 align-items-center">
          <div>
            <span>Heure de passage :</span>
            <div class="_time">
              <i class="fa-solid fa-clock"></i>
              <span>{{ estimatedTimeOfArrivalCtrl.value | date:'HH:mm'}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 _content_poi" id="poi-form-wrap">
        <form (ngSubmit)="saveEstimation()" [formGroup]="poiForm">
          <div *ngIf="workingPoi?.origin=='organizer' || workingPoi.isFinishLine || isStatisticMode || isValidationMode"
               class="row">
            <div class="row">
              <div class="col-12">
                <h4>{{workingPoi.name}}</h4>
              </div>
            </div>
          </div>
          <div
            *ngIf="workingPoi?.origin!=='organizer' && !workingPoi.isFinishLine && !isStatisticMode && !isValidationMode">

            <div class="row">
              <div class="col-12">
                <div class="form-group __input">
                  <mat-form-field class="_wrap_input">
                    <label i18n>Nom du point *</label>
                    <input maxlength="40" formControlName="name" matInput required type="text">
                    <mat-hint align="end">{{nameCtrl.value.length}} / 40</mat-hint>
                    <mat-error
                      *ngIf="nameCtrl.hasError('required')">
                      Champ obligatoire
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group __input">
                  <mat-form-field class="_wrap_input">
                    <label i18n>Kilométrage (km) *</label>
                    <input matInput
                           formControlName="distance"
                           pattern="^(?:[1-9]\d*|0)?(?:\.\d+)?$"
                           required
                           step="1"
                           min=0
                           [max]="maxDistance"
                           type="number">
                    <mat-error
                      *ngIf="distanceCtrl.hasError('required')">
                      Champ obligatoire
                    </mat-error>
                    <mat-error
                      *ngIf="distanceCtrl.dirty && distanceCtrl.hasError('pattern')">
                      Valeur positive attendue
                    </mat-error>
                    <mat-error
                      *ngIf="distanceCtrl.dirty && distanceCtrl.hasError('max')">
                      La distance entrée est hors parcours
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <span *ngIf="!workingPoi?.previousPoiName" class="subsection-title">Temps estimé du <strong>départ</strong> à <strong>{{
              nameCtrl.value }}</strong> :</span>
            <span *ngIf="workingPoi?.previousPoiName" class="subsection-title">Temps estimé de <strong>{{
              workingPoi?.previousPoiName }}</strong> à <strong>{{ nameCtrl.value }}</strong>  :</span>
          </div>
          <div [formGroup]="estimationForm">

            <div *ngIf="!isValidationMode && !isStatisticMode else readonlyEstimations" class="row">
              <div class="col-md-12">
                <app-duration (durationChange)="updateEstimatedDuration($event)"
                              [durationInSeconds]="estimatedDurationInSeconds"
                              #estimatedDuration></app-duration>
              </div>
              <div class="col-md-6 justify-content-start">
                <div class="form-group __input">
                  <mat-form-field class="_wrap_input">
                    <label i18n>Vmoy. estimée (km/h)*</label>
                    <input matInput type="number"
                           formControlName="estimatedAverageSpeed"
                           min=0
                           pattern="^(?:[1-9]\d*|0)?(?:\.\d+)?$"
                           required>
                    <mat-error
                      *ngIf="estimatedAverageSpeedCtrl.hasError('required')">
                      Champ
                      obligatoire
                    </mat-error>
                    <mat-error
                      *ngIf="estimatedAverageSpeedCtrl.dirty && estimatedAverageSpeedCtrl.hasError('pattern')">
                      Valeur
                      positive attendue
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6 justify-content-start">
                <div class="form-group __input">
                  <label i18n>Allure moy. ('/km)</label>
                  <div class="value d-flex align-items-center ">
                    <div class="form-group __input">
                      <mat-form-field class="_wrap_input">
                        <input matInput type="number"
                               [formControl]="estimatedAveragePaceMinCtrl"
                               min="0" max="59"
                               step="1" pattern="\d*" required>
                        <mat-error>!</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="_sep">:</div>
                    <div class="form-group __input">
                      <mat-form-field class="_wrap_input">
                        <input matInput type="number"
                               [formControl]="estimatedAveragePaceSecCtrl"
                               min="0" max="59"
                               step="1" pattern="\d*" required>
                        <mat-error>!</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ng-template #readonlyEstimations>
              <div class="readonly-estimation-wrap">
                <div class="row">
                  <div class="col-md-12 d-flex flex-column">
                    <label i18n class="mb-0">Temps estimé : </label>
                    <span>{{ (estimatedDurationInSeconds | duration) || '...'}}</span>
                  </div>
                  <div class="col-md-6  d-flex flex-column">
                    <label i18n class="mb-0">Allure Moy. : </label>
                    <span>{{ workingPoi.trailerEstimation.estimatedAveragePace}} min/km</span>
                  </div>
                  <div class="col-md-6  d-flex flex-column">
                    <label i18n class="mb-0">Vitesse Moy. : </label>
                    <span>{{ workingPoi.trailerEstimation.estimatedAverageSpeed}} km/h</span>
                  </div>
                </div>
              </div>


              <div class="row mt-3 pt-3 _validation_form_wrap">
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                  <label i18n>Temps mesuré : </label>
                </div>
                <div class="col-md-8">
                  <app-duration (durationChange)="updateMeasuredDuration($event)"
                                [durationInSeconds]="measuredDurationInSeconds"
                                #measuredDuration></app-duration>
                </div>
              </div>

            </ng-template>

          </div>


          <div class="d-flex justify-content-center btn-action-wrap _full">
            <button matDialogClose class="_sp_btn _white" type="button">Annuler</button>
            <button *ngIf="workingPoi?.origin=='trailer' && workingPoi?.internId" class="_sp_btn _blue" type="button"
                    (click)="deletePoiTrailer();">Supprimer
            </button>
            <button type="submit" class="_sp_btn _blue">Valider</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-dialog-content>

