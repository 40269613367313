<div class="_contain  w-100">
  <form (ngSubmit)="searchRace()" [formGroup]="searchRaceForm"
        class="_search_lite d-flex flex-column px-1 px-md-3 px-lg-4 "
        style="width: 100%;">

    <div class="form-group _no_marge __input">
      <mat-form-field class="_wrap_input _small">
        <label i18n>Par nom</label>
        <input matInput formControlName="name" required>
      </mat-form-field>
    </div>

    <div class="form-group _no_marge __input">
      <mat-form-field class="_wrap_input">
        <label i18n>Par ville</label>
        <input matInput type="text" #autocompleteLocation name="autocomplete" id="autocomplete"
               formControlName="location" required>
      </mat-form-field>
    </div>

    <div class="form-group _no_marge __input">
      <mat-form-field class="_wrap_input _icon">
        <label i18n>Date de début *</label>
        <div class="__icon_input">
          <input matInput [min]='today' [matDatepicker]="dateBeginDatepicker" formControlName="dateSearchStart"
                 #datbegin
                 (focus)="dateBeginDatepicker.open()" (click)="dateBeginDatepicker.open()" readonly required>
          <mat-datepicker-toggle matSuffix [for]="dateBeginDatepicker"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #dateBeginDatepicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="form-group _no_marge __input">
      <mat-form-field class="_wrap_input _icon">
        <label i18n>Date de fin *</label>
        <div class="__icon_input">
          <input matInput [min]='dateSearchStartCtrl.value' [matDatepicker]="dateEndDatepicker"
                 formControlName="dateSearchEnd" #dateend (focus)="dateEndDatepicker.open()"
                 (click)="dateEndDatepicker.open()" readonly required>
          <mat-datepicker-toggle matSuffix [for]="dateEndDatepicker"></mat-datepicker-toggle>
        </div>
        <mat-datepicker #dateEndDatepicker></mat-datepicker>
        <mat-error *ngIf="dateSearchEndCtrl.hasError('required')">Champ obligatoire</mat-error>
        <mat-error *ngIf="dateSearchEndCtrl.hasError('matDatepickerMin')">La date de fin doit être supérieur à la date
          de début
        </mat-error>
      </mat-form-field>
    </div>

    <button class="_sp_btn _blue _full my-5" name="" type="submit">
      <span>Rechercher</span>
    </button>
  </form>
</div>
