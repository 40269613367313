import {Directive, ElementRef} from '@angular/core';
import {GoogleMapService} from '../services/common/google-map.service';

@Directive({
  selector: '[appPlaceAutocomplete]'
})
export class PlaceAutocompleteDirective {

  constructor(private el: ElementRef, private googlePlaceService: GoogleMapService) {
    this.googlePlaceService.getPlaceAutocomplete(this.el);
  }
}
