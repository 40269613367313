import { RaceModel } from "./race.model";
import { PoiModel } from "./Pois/poi.model";
import { RoadbookPicture } from "./roadbook-picture";
import { TrailerModel } from "./trailer.model";
import { EstimationModel } from "./Pois/estimation.model";

export class RoadbookModel {
  id: number;
  name: string;
  status: "draft" | "complete" | "validated" | "archived" | "deleted";

  defaultAverageSpeed: number;

  estimatedAverageSpeed: number;
  measuredAverageSpeed: number;

  estimatedTimeOfArrival: Date;
  measuredTimeOfArrival: Date;

  estimatedAveragePace: string;
  measuredAveragePace: string;

  estimatedDuration: number; // en seconde
  measuredDuration: number; // en seconde
  globalAccurateEstimationPercentage: number;

  // Poi TRAILER + estimation
  pois: Array<PoiModel> = [];
  poisTrailer: Array<PoiModel> = [];

  roadbookPictures: Array<RoadbookPicture> = new Array<RoadbookPicture>();

  race: RaceModel;
  trailer: TrailerModel;

  // PASSPORT

  passportFirstname: string;
  passportLastname: string;
  passportAddress: string;
  passportLocality: string;
  passportPostalCode: string;
  passportCountry: string;
  passportBloodGroup: string;

  emergencyFirstname: string;
  emergencyLastname: string;
  emergencyEmail: string;
  emergencyPhone: string;

  progress: number; // pourcentage de completion du roadbook
  needPdfGeneration = true;

  estimations: Array<EstimationModel>;
  estimationsPoiOrga: Array<EstimationModel>;

  created: Date;
  updated: Date;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
