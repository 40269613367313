import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { RoadbookModel } from '../../../../models/roadbook.model';
import { RaceModel } from '../../../../models/race.model';
import { DatetimeService } from '../../../../services/common/datetime.service';
import { Router } from '@angular/router';
import { RoadbookService } from '../../../../services/roadbook/roadbook.service';
import { FileService } from '../../../../services/common/file.service';
import { SpinnerService } from '../../../../services/common/spinner.service';
import { SnackbarService } from '../../../../services/notifications/snackbar.service';
import { DialogService } from '../../../../services/common/dialog.service';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';

@Component({
             selector: 'app-roadbook-list-item',
             templateUrl: './roadbook-list-item.component.html',
             styleUrls: ['./roadbook-list-item.component.scss']
           })
export class RoadbookListItemComponent implements OnInit {

  @Input() roadbook: RoadbookModel;

  intervalSoonLimit = 2592000; // limite  en secondes  à partir de laquelle on passe en mode warning (30 jours)
  intervalImminentLimit = 432000; // limite de jour à partir de laquelle on passe en mode danger (5 jours)
  intervalToRace!: number;
  intervalToRaceLabel: string;
  intervalToRacePrefix: string;
  private intervalTimer: Subscription;

  constructor(private datetimeService: DatetimeService,
              public viewContainerRef: ViewContainerRef,
              private router: Router,
              private roadbookService: RoadbookService,
              private fileService: FileService,
              private spinner: SpinnerService,
              private snackbarService: SnackbarService,
              private dialogsService: DialogService) {
  }

  ngOnInit(): void {

    this.intervalToRace = this.getIntervalToRace(this.roadbook.race);
    const label = this.getIntervalLabel(this.intervalToRace);
    this.intervalToRaceLabel = label.intervalLabel;
    this.intervalToRacePrefix = label.intervalPrefix;

    // this.intervalTimer = interval(5000).subscribe(() => {
    //
    // });
  }

  editRoadbook() {
    this.router.navigate(['/trailer/roadbooks', this.roadbook.id, 'edit']);
  }

  /** Retourne l'échéance
   *
   * @param date
   * @returns {number}
   */
  getIntervalToRace(race: RaceModel) {

    const fullRaceDepartureDate = race.date;

    return this.datetimeService.getIntervalToToday(fullRaceDepartureDate, 'seconds');
  }

  /**
   *
   * @param date
   * @returns {string}
   */
  getIntervalLabel(intervalInSeconds: any) {
    return this.datetimeService.getIntervalLabel(intervalInSeconds);
  }

  public downloadRoadbookPdf() {

    // si on a des roadbook sur la course

    const extraAction: string = 'Non, télécharger la version standard';

    this.dialogsService
      .confirm('Téléchargement du roadbook', 'Avant de le télécharger, souhaitez-vous personnaliser votre roadbook ?',
               this.viewContainerRef, extraAction, null, 'Oui, Personnaliser mon roadbook ')
      .pipe(filter(res => (res && (res === 'confirm' || res === 'extra'))))
      .subscribe((res) => {
        if (res === 'extra') {
          this.previewOrDowloadRoadbookPdf('download');
        } else if (res === 'confirm') {
          this.roadbookService.currentRoadbook = this.roadbook;
          this.router.navigate(['/trailer/roadbooks/' + this.roadbook.id + '/edit/passport']);
        }
      }, error => {
        this.snackbarService.openDanger('Erreur lors de l\'annulation de la course');
      });
  }

  private previewOrDowloadRoadbookPdf(action: string = 'preview') {

    const messageSpinner = 'Téléchargement du roadbook en cours...';

    this.spinner.start(messageSpinner);

    return this.roadbookService.getRoadbookPdfFile(this.roadbook.id)
      .subscribe(dataBlob => {
                   if (action === 'preview') {
                     this.fileService.previewFile(dataBlob, 'application/pdf');
                   }
                   if (action === 'download') {
                     this.fileService.downloadFile(dataBlob, 'splits_roadbook_' + this.roadbook.id + '.pdf', 'application/pdf');
                   }

                   this.spinner.stop();
                 },
                 error => {
                   this.snackbarService.openDanger('Erreur lors de la génération du PDF', 'OK');
                   this.spinner.stop();
                 });
  }

  /** Redirection vers la validation du roadbook
   *
   * @param roadbook
   */
  validateRoadbook() {
    this.router.navigate(['/trailer/roadbooks', this.roadbook.id, 'validate']);
  }

  editOrValidateRoadbook() {
    if (this.getIntervalToRace(this.roadbook.race) > 0) {
      this.editRoadbook();
    } else {
      if (this.roadbook.status === 'draft' || this.roadbook.status === 'complete') {
        this.validateRoadbook();
      } else if (this.roadbook.status === 'validated') {
        // this.statisticsRoadbook();
      }
    }
  }
}
