import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { RaceModel } from "../../models/race.model";
import { Router } from "@angular/router";
import { ORGANIZER, TRAILER, UserModel } from "../../models/user.model";
import { EventModel } from "../../models/event.model";
import { environment } from "../../../environments/environment";
import { PoiModel } from "../../models/Pois/poi.model";
import { Trkpt } from "../../classes/trkpt";
import LatLng = google.maps.LatLng;
import LatLngLiteral = google.maps.LatLngLiteral;

declare var MarkerClusterer: any;

@Component({
  selector: "map-cmp",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnChanges, AfterViewInit {
  urlSplitsMarker = "/assets/img/app/gmap/Picto_Map_Pin.svg";
  urlGreyMarker = "/assets/img/app/icons/maps-marker-grey.png";
  urlSplitsGreenMarker = "/assets/img/app/icons/Picto_Map_Pin_Green.svg";
  startPointMarker = "/assets/icons/chart/Picto_Roadbook_Start_green.svg";
  finishPointMarker = "/assets/icons/chart/Picto_Roadbook_Finish.svg";
  foodStationMarker =
    "/assets/icons/chart/Picto_Roadbook_FoodAndBeverage_green.svg";
  beverageStationMarker =
    "/assets/icons/chart/Picto_Roadbook_Beverage_green.svg";
  lifeHouseMarker = "/assets/icons/chart/Picto_Roadbook_LifeHouse_green.svg";
  timeLimitMarker = "/assets/icons/chart/Picto_Roadbook_TimeLimit_green.svg";
  checkPointMarker = "/assets/icons/chart/Picto_Roadbook_Checkpoint_green.svg";

  @Input() height: number;
  @Input() lat = environment.gmap.default.lat;
  @Input() lng = environment.gmap.default.lng;
  @Input() zoom = environment.gmap.default.zoom;

  @Input() race: RaceModel;
  @Input() pois: Array<PoiModel>;
  @Input() poisOrga: Array<PoiModel> = [];
  @Input() poisTrailer: Array<PoiModel> = [];

  // ## GMAP ##
  // latitude par défaut de la France
  map: any;
  mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

  markers: any = [];

  markersGroup: any = [];

  style: any = [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#6195a0",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f2f2f2",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#e6f3d6",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#f4d2c5",
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text",
      stylers: [
        {
          color: "#4e4e4e",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#f4f4f4",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#787878",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#eaf6f8",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#eaf6f8",
        },
      ],
    },
  ];

  newMarker: google.maps.Marker;

  @ViewChild("gmap", { static: true }) gmap;

  @Output() markerClicked: EventEmitter<number> = new EventEmitter<number>();

  constructor(private router: Router) {
    this.mapBounds = new google.maps.LatLngBounds();
  }

  ngOnInit() {
    this.map = new google.maps.Map(this.gmap.nativeElement, {
      center: { lat: +this.lat, lng: +this.lng },
      streetViewControl: false,
      rotateControl: false,
      styles: this.style,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 7,
    });
  }

  ngAfterViewInit() {
    // This is needed to set the zoom after fitbounds,
    google.maps.event.addListener(this.map, "zoom_changed", () => {
      const zoomChangeBoundsListener = google.maps.event.addListener(
        this.map,
        "bounds_changed",
        function (event) {
          if (this.getZoom() > 12) {
            // Change max/min zoom here
            this.setZoom(12);
          }
        }
      );
      google.maps.event.removeListener(zoomChangeBoundsListener);
    });
    this.map.initialZoom = true;
  }

  /** déclenchement de la mise à jour du composent au change de la race
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.race) {
      const currentRaceInstance: SimpleChanges = changes.race.currentValue;
      const previousRaceInstance: SimpleChanges = changes.race.previousValue;

      if (
        typeof currentRaceInstance !== "undefined" &&
        currentRaceInstance !== previousRaceInstance
      ) {
      }
    }
  }

  addStartPointMarker(startPoint: Trkpt) {
    this.addOneMarker(
      "start",
      "Départ",
      startPoint.lat,
      startPoint.lon,
      5,
      false,
      null,
      this.startPointMarker
    );
  }

  addFinishPointMarker(finishPoint: Trkpt) {
    this.addOneMarker(
      "end",
      "Arrivée",
      finishPoint.lat,
      finishPoint.lon,
      5,
      false,
      null,
      this.finishPointMarker
    );
  }

  addOnePoiTrailerMarker(poiTrailer: PoiModel) {
    if (poiTrailer) {
      const infoWindowContent =
        "<div id='content'>" +
        "<p style='margin-bottom: 0.3rem;font-weight: 500;font-size: 1rem; color:'#240457'>" +
        poiTrailer.name +
        "</p>" +
        "<p style='margin-bottom: 0.5rem;'>" +
        "<i class='icon-splits_proximity splits-icon-md primary'></i>" +
        poiTrailer.distance +
        " km " +
        "<i class='icon-splits_elevation splits-icon-md primary'></i>" +
        poiTrailer.elevation +
        " m D+" +
        "</p>" +
        "</div>";

      this.addOneMarker(
        poiTrailer.internId,
        poiTrailer.name,
        poiTrailer.latitude,
        poiTrailer.longitude,
        10,
        false,
        infoWindowContent
      );
    }
  }

  addOnePoiOrgaMarker(poiOrga: PoiModel) {
    if (poiOrga) {
      let infoWindowContent =
        "<div id='content'>" +
        "<p style='margin-bottom: 0.3rem;font-weight: 500;font-size: 1rem; color:#240457'>" +
        poiOrga.name +
        "</p>";

      if (poiOrga.poiTypes && poiOrga.poiTypes.length > 0) {
        infoWindowContent +=
          "<div class=\"d-flex justify-content-center align-items-center\" style='margin-bottom: 0.5rem;'>";
        poiOrga.poiTypes.forEach((poiType) => {
          infoWindowContent +=
            ' <div role = "button" class= "__icon _xs _icon-poi-' +
            poiType.code +
            "\" style='margin: 0 0.5rem'>" +
            " </div>";
        });
        infoWindowContent += "</div>";
      }

      infoWindowContent +=
        "<p style='margin-bottom: 0.5rem;'>" +
        "<i class='icon-splits_proximity splits-icon-md primary'></i>" +
        poiOrga.distance +
        " km " +
        "<i class='icon-splits_elevation splits-icon-md primary'></i>" +
        poiOrga.elevation +
        " m D+" +
        "</p>" +
        "</div>";

      this.addOneMarker(
        poiOrga.internId,
        poiOrga.name,
        poiOrga.latitude,
        poiOrga.longitude,
        10,
        false,
        infoWindowContent,
        this.urlSplitsMarker
      );
    }
  }

  addPoisTrailerMarkers(poisTrailer: Array<PoiModel>) {
    if (poisTrailer) {
      poisTrailer.forEach((poiTrailer: PoiModel) =>
        this.addOnePoiTrailerMarker(poiTrailer)
      );
    }
  }

  addPoisOrgaMarkers(poisOrga: Array<PoiModel>) {
    if (poisOrga) {
      poisOrga.forEach((poiOrga: PoiModel) =>
        this.addOnePoiOrgaMarker(poiOrga)
      );
      this.manageFitBounds();
    }
  }

  /** Ajout d'une liste de marqueurs
   *
   * @param races
   */
  addRacesMarkers(races: Array<RaceModel>, user: UserModel) {
    this.clearMarkers();
    if (typeof races !== "undefined") {
      races.forEach((race: RaceModel) => {
        if (race.latitude && race.longitude) {
          const infoWindowContent =
            "<div id='content'>" +
            "<p style='margin-bottom: 0.3rem;font-weight: 500;font-size: 1rem; color:'#240457'>" +
            race.name +
            "</p>" +
            "<p style='margin-bottom: 0.5rem;font-weight: 500;font-size: 0.7rem; color:'#240457'>" +
            race.event?.name +
            "</p>" +
            "<p style='margin-bottom: 0.5rem;'>" +
            "<i class='icon-splits_proximity splits-icon-md primary'></i>" +
            race.distance +
            " km " +
            "<i class='icon-splits_elevation splits-icon-md primary'></i>" +
            race.ascendingElevation +
            " m D+" +
            "</p>" +
            "</div>";

          const urlMarker: string =
            (user.type_user == ORGANIZER &&
              race.event.organizerId === user.id) ||
            user.type_user == TRAILER
              ? this.urlSplitsMarker
              : this.urlGreyMarker;

          this.addOneMarker(
            race.id,
            race.name,
            race.latitude,
            race.longitude,
            10,
            false,
            infoWindowContent,
            urlMarker
          );
        }
      });

      this.manageFitBounds();

      const mcOptions = {
        gridSize: 50,
        averageCenter: false,
        maxZoom: 12,
        minimumClusterSize: 3,
        imagePath: "/assets/img/app/gmap/m",
      };
      const markerCluster = new MarkerClusterer(
        this.map,
        this.markersGroup,
        mcOptions
      );
    }
  }

  /** Ajout d'une liste de marqueurs
   *
   * @param races
   */
  addEventMarkers(events: Array<EventModel>) {
    this.clearMarkers();
    if (typeof events !== "undefined") {
      events.forEach((event: EventModel) => {
        if (event.latitude && event.longitude) {
          const infoWindowContent =
            "<div class=\"_info_map\" id='content'>" +
            "<h4>" +
            event.name +
            "</h4>" +
            '<span class="_date">' +
            event.dateEventDisplay +
            "</span>" +
            "</div>";
          const urlMarker: string = this.urlSplitsMarker;

          this.addOneMarker(
            event.id,
            event.name,
            event.latitude,
            event.longitude,
            10,
            false,
            infoWindowContent,
            urlMarker
          );
        }
      });

      this.manageFitBounds();

      const mcOptions = {
        gridSize: 50,
        averageCenter: false,
        maxZoom: 12,
        minimumClusterSize: 3,
        imagePath: "/assets/img/app/gmap/m",
      };
      const markerCluster = new MarkerClusterer(
        this.map,
        this.markersGroup,
        mcOptions
      );
    }
  }

  /** Ajout d'un marqueur Sur la Map
   *
   * @param title
   * @param position
   */
  addOneMarker(
    id,
    title,
    lat,
    lng,
    zoom = 10,
    center = false,
    infoWindowContent: string = null,
    urlMarker = this.urlSplitsMarker
  ) {
    const position = new google.maps.LatLng(+lat, +lng);

    // on étend les frontières
    this.mapBounds.extend(position);
    const marker = new google.maps.Marker({
      position,
      map: this.map,
      animation: google.maps.Animation.DROP,
      title,
      icon: {
        url: urlMarker,
        scaledSize: new google.maps.Size(26, 40),
      },
    });
    // ajout de la fenêtre d'info
    if (infoWindowContent) {
      const infowindow = new google.maps.InfoWindow({
        content: infoWindowContent,
        maxWidth: 200,
      });

      marker.addListener("mouseover", () => infowindow.open(this.map, marker));
      marker.addListener("mouseout", () => infowindow.close());
    }

    marker.addListener(
      "click",
      function () {
        this.markerClicked.emit(id);
      }.bind(this)
    );

    this.markers.push({ id, marker });
    this.markersGroup.push(marker);

    if (center) {
      this.centerMap(position, zoom);
    }
  }

  /** Gestion du bounce d'un pin sur la map
   *
   * @param indexMarker
   * @param action
   */
  manageBounceMarkerByItemId(itemId: number, action: string = null) {
    if (typeof itemId !== "undefined" && typeof this.markers !== "undefined") {
      const markerToBounce = this.markers.filter(
        (marker) => marker.id === itemId
      );

      if (typeof markerToBounce[0] !== "undefined") {
        const marker: google.maps.Marker = markerToBounce[0].marker;

        if (typeof marker !== "undefined") {
          if (action === "start") {
            marker.setAnimation(google.maps.Animation.BOUNCE);
          }
          if (action === "stop") {
            marker.setAnimation(null);
          }
        }
      }
    }
  }

  /**
   *
   * @param point
   */
  manageFitBounds() {
    const markersCount = this.markers.length;

    // Entend la carte en fonction des frontieres définies par l'ensemble des marqueurs
    if (markersCount > 0) {
      this.map.fitBounds(this.mapBounds);

      // si les points sont trop rapprochés, on étend les frontières pour "dezoomer"
      // on rajoute deux points pour garder le pointer centré
      if (
        this.mapBounds.getNorthEast() &&
        this.mapBounds.getNorthEast().equals(this.mapBounds.getSouthWest())
      ) {
        const extendPoint1 = new google.maps.LatLng(
          this.mapBounds.getNorthEast().lat() + 0.02,
          this.mapBounds.getNorthEast().lng() + 0.02
        );
        const extendPoint2 = new google.maps.LatLng(
          this.mapBounds.getNorthEast().lat() - 0.02,
          this.mapBounds.getNorthEast().lng() - 0.02
        );
        this.mapBounds.extend(extendPoint1);
        this.mapBounds.extend(extendPoint2);
      }
      this.map.fitBounds(this.mapBounds);
    }
  }

  /** Ajout d'un tracé KML
   *
   * @param url
   */
  addKmlLayer(url) {
    // on positione le tracé de la course
    const ctaLayer = new google.maps.KmlLayer({
      url,
      map: this.map,
    });
  }

  refreshMap() {
    const position = new google.maps.LatLng(this.lat, this.lng);
    if (this.map) {
      // on recentre sur le marker/*
      this.map.setCenter(position);
      this.map.setZoom(this.zoom);
    }
  }

  centerMap(position: LatLng | LatLngLiteral, zoom = 10) {
    if (this.map) {
      // on recentre sur le marker/*
      this.map.setCenter(position);
      this.map.setZoom(zoom);
    }
  }

  // Sets the map on all markers in the array.
  setMapOnAll(map) {
    this.markers.forEach((marker: { id; marker }) => {
      marker.marker.setMap(map);
    });
  }

  // Removes the markers from the map, but keeps them in the array.
  clearMarkers() {
    this.setMapOnAll(null);
    this.markers = [];
    this.markersGroup = [];
    this.mapBounds = new google.maps.LatLngBounds();
  }

  // Shows any markers currently in the array.
  showMarkers() {
    this.setMapOnAll(this.map);
  }

  removeOneMarker(id) {
    this.markers.forEach((marker: { id; marker }) => {
      if (marker.id === id) {
        marker.marker.setMap(null);
      }
    });
  }

  // Deletes all markers in the array by removing references to them.
  deleteMarkers() {
    this.clearMarkers();
    this.markers = [];
  }
}
