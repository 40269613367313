import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  of,
  Subject,
  switchMap,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { MapGeocoder, MapGeocoderResponse } from "@angular/google-maps";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleMapService {
  private placeSubject: Subject<{
    place: google.maps.places.PlaceResult;
    refererComponent: string;
  }> = new Subject<{
    place: google.maps.places.PlaceResult;
    refererComponent: string;
  }>();
  public placeObservable = this.placeSubject.asObservable();
  public apiLoadedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public boundChanged: Subject<google.maps.LatLngBounds> =
    new Subject<google.maps.LatLngBounds>();

  constructor(private httpClient: HttpClient, private geocoder: MapGeocoder) {}

  loadApi() {
    this.httpClient
      .jsonp(
        "https://maps.googleapis.com/maps/api/js?libraries=places&key=" +
          environment.gmap.apiKey,
        "callback"
      )
      .pipe(catchError(() => of(false)))
      .subscribe(() => {
        console.log("loaded ");
        this.apiLoadedSubject.next(true);
      });
  }

  getPlaceAutocomplete(addressText: any): any {
    const autocomplete = new google.maps.places.Autocomplete(
      addressText.nativeElement,
      {
        componentRestrictions: {
          country: ["FR", "DE"],
        },
        fields: ["formatted_address", "address_components", "geometry"],
        strictBounds: false,
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      const refererComponent: string = addressText.nativeElement.getAttribute(
        "data-refererComponent"
      );
      this.placeSubject.next({ place, refererComponent });
    });
  }

  getFormattedAddress(place: any): any {
    return place.formatted_address ?? null;
  }

  getGeometryLocation(place: any): any {
    return place.geometry && place.geometry.location
      ? place.geometry.location
      : null;
  }

  getAddrComponent(place: any, componentTemplate: any): any {
    let result;
    for (const component of place.address_components) {
      const addressType = component.types[0];
      if (componentTemplate[addressType]) {
        result = component[componentTemplate[addressType]];
        return result;
      }
    }
  }

  getLatitude(place: any) {
    const location = this.getGeometryLocation(place);
    return location.lat();
  }

  getLongitude(place: any) {
    const location = this.getGeometryLocation(place);
    return location.lng();
  }

  getAddrGeometryLocation(
    addressText: any
  ): Observable<google.maps.LatLngLiteral> {
    return this.geocoder.geocode({ address: addressText }).pipe(
      filter((results: MapGeocoderResponse) => {
        return results.status === "OK";
      }),
      switchMap((results: MapGeocoderResponse) =>
        of(results.results[0].geometry.location.toJSON())
      )
    );
  }

  getStreetNumber(place: any): any {
    const COMPONENT_TEMPLATE = { street_number: "short_name" };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getStreet(place: any): any {
    const COMPONENT_TEMPLATE = { route: "long_name" };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getCity(place: any): any {
    const COMPONENT_TEMPLATE = { locality: "long_name" };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getState(place: any): any {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: "short_name" };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getDistrict(place: any): any {
    const COMPONENT_TEMPLATE = {
      administrative_area_level_2: "short_name",
    };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getCountryShort(place: any): any {
    const COMPONENT_TEMPLATE = { country: "short_name" };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getCountry(place: any): any {
    const COMPONENT_TEMPLATE = { country: "long_name" };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getPostCode(place: any): any {
    const COMPONENT_TEMPLATE = { postal_code: "long_name" };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }

  getPhone(place: any): any {
    const COMPONENT_TEMPLATE = {
      formatted_phone_number: "formatted_phone_number",
    };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
}
