import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { PoiBaseComponent } from '../poi-base-component';
import { debounceTime } from 'rxjs/operators';
import { EstimationModel } from '../../../models/Pois/estimation.model';

@Component({
             selector: 'app-add-estimation',
             templateUrl: './add-estimation.component.html',
             styleUrls: ['./add-estimation.component.scss']
           })
export class AddEstimationComponent extends PoiBaseComponent implements OnInit {

  addSuccessfully: boolean;

  isValidationMode: boolean;
  isStatisticMode: boolean;

  estimationForm: UntypedFormGroup;
  estimatedAveragePaceMinCtrl = new UntypedFormControl('');
  estimatedAveragePaceSecCtrl = new UntypedFormControl('');
  estimatedTimeOfArrivalCtrl = new UntypedFormControl('');

  validationForm: UntypedFormGroup;

  measuredAverageSpeedCtrl = new UntypedFormControl('');
  measuredAveragePaceCtrl = new UntypedFormControl('');
  measuredDurationCtrl = new UntypedFormControl('');
  measuredTimeOfArrivalCtrl = new UntypedFormControl('');

  @ViewChild('roadbookPassportForm') roadbookPassportForm: NgForm;
  private estimation: EstimationModel;

  estimatedDurationInSeconds: number;
  measuredDurationInSeconds: number;

  ngOnInit() {
    super.ngOnInit();

    this.isValidationMode = this.data.isValidationMode;
    this.isStatisticMode = this.data.isStatisticMode;

    this.buildForm();

    this.populatePoiInfoWithPreviousPoi(this.workingPoi.distance);

    this.poiForm.patchValue(this.workingPoi);

    if (this.workingPoi.trailerEstimation) {
      this.estimatedAverageSpeedCtrl.setValue(this.workingPoi.trailerEstimation.estimatedAverageSpeed);
      this.estimatedAveragePaceCtrl.setValue(this.workingPoi.trailerEstimation.estimatedAveragePace);
      this.setPaceFields(this.workingPoi.trailerEstimation.estimatedAveragePace);
      this.estimatedDurationInSeconds = this.workingPoi.trailerEstimation.estimatedDuration;
      this.updateEstimatedDuration(this.estimatedDurationInSeconds);
      if (this.isValidationMode || this.isStatisticMode) {
        this.measuredDurationInSeconds = this.workingPoi.trailerEstimation.measuredDuration ?? this.estimatedDurationInSeconds;
        this.updateMeasuredDuration(this.measuredDurationInSeconds);
      }
    } else {
      if (this.workingPoi.user.averageSpeed) {
        this.estimatedAverageSpeedCtrl.setValue(this.workingPoi.user.averageSpeed, {emitEvent: false});
        const averagePace: string = this.convertorService.getPaceFromSpeed(this.workingPoi.user.averageSpeed);
        this.estimatedAveragePaceCtrl.setValue(averagePace, {emitEvent: false});
        this.setPaceFields(this.convertorService.getPaceFromSpeed(this.workingPoi.user.averageSpeed));
        this.estimatedDurationInSeconds = (this.workingPoi.distance) ? this.convertorService.getDurationFromSpeed(
          this.workingPoi.distanceInBetween,
          this.workingPoi.user.averageSpeed) : 0;
        this.updateEstimatedDuration(this.estimatedDurationInSeconds);
        this.setEstimatedTimeOfArrival(this.estimatedDurationInSeconds);
      }
    }

  }

  buildForm() {
    super.buildForm();

    this.estimationForm = this.formBuilder.group({
                                                   estimatedDuration: this.estimatedDurationCtrl,
                                                   estimatedTimeOfArrival: this.estimatedTimeOfArrivalCtrl,
                                                   estimatedAveragePace: this.estimatedAveragePaceCtrl,
                                                   estimatedAverageSpeed: this.estimatedAverageSpeedCtrl,
                                                   measuredDuration: this.measuredDurationCtrl,
                                                   measuredTimeOfArrival: this.measuredTimeOfArrivalCtrl,
                                                   measuredAveragePace: this.measuredAveragePaceCtrl,
                                                   measuredAverageSpeed: this.measuredAverageSpeedCtrl
                                                 });

    this.poiForm.addControl('trailerEstimation', this.estimationForm);
  }

  activateChangeDetection() {
    super.activateChangeDetection();

    this.estimatedDurationCtrl.valueChanges.subscribe(estimatedDurationInSecond => {
      this.estimatedDurationInSeconds = estimatedDurationInSecond;
      const speed: number = this.convertorService.getSpeedFromTime(this.workingPoi.distanceInBetween,
                                                                   estimatedDurationInSecond);
      const pace: string = this.convertorService.getPaceFromSpeed(speed);
      this.estimatedAveragePaceCtrl.setValue(pace, {emitEvent: false});
      this.estimatedAverageSpeedCtrl.setValue(speed, {emitEvent: false});
      this.setPaceFields(pace);
      this.setEstimatedTimeOfArrival(estimatedDurationInSecond);
    });

    this.measuredDurationCtrl.valueChanges.subscribe(measuredDurationInSecond => {
      this.measuredDurationInSeconds = measuredDurationInSecond;
      const speed: number = this.convertorService.getSpeedFromTime(this.workingPoi.distanceInBetween,
                                                                   measuredDurationInSecond);
      const pace: string = this.convertorService.getPaceFromSpeed(speed);
      this.measuredAveragePaceCtrl.setValue(pace, {emitEvent: false});
      this.measuredAverageSpeedCtrl.setValue(speed, {emitEvent: false});
      this.setPaceFields(pace);
      this.setMeasuredTimeOfArrival(measuredDurationInSecond);
    });

    this.estimatedAverageSpeedCtrl.valueChanges.pipe(debounceTime(300)).subscribe(speed => {
      const pace: string = this.convertorService.getPaceFromSpeed(speed);
      this.estimatedAveragePaceCtrl.setValue(pace, {emitEvent: false});
      this.setPaceFields(pace);
      this.estimatedDurationInSeconds = this.convertorService.getDurationFromSpeed(this.workingPoi.distanceInBetween, speed);
      this.estimatedDurationCtrl.setValue(this.estimatedDurationInSeconds, {emitEvent: false});
      this.setEstimatedTimeOfArrival(this.estimatedDurationInSeconds);
    });

    this.estimatedAveragePaceCtrl.valueChanges.pipe(debounceTime(300)).subscribe(pace => {
      const speed: number = this.convertorService.getSpeedFromPace(pace);
      this.estimatedAverageSpeedCtrl.setValue(speed, {emitEvent: false});
      this.estimatedDurationInSeconds = this.convertorService.getDurationFromSpeed(this.workingPoi.distanceInBetween, speed);
      this.estimatedDurationCtrl.setValue(this.estimatedDurationInSeconds, {emitEvent: false});
      this.setEstimatedTimeOfArrival(this.estimatedDurationInSeconds);
    });

    this.estimatedAveragePaceMinCtrl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      const min: number = value;
      const sec: number = this.estimatedAveragePaceSecCtrl.value;
      this.estimatedAveragePaceCtrl.setValue(this.convertorService.formatIntoPace(min, sec));
    });

    this.estimatedAveragePaceSecCtrl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      const min: number = this.estimatedAveragePaceMinCtrl.value;
      const sec: number = value;
      this.estimatedAveragePaceCtrl.setValue(this.convertorService.formatIntoPace(min, sec));
    });

  }

  setEstimatedTimeOfArrival(estimatedDurationInSecond) {
    this.estimatedTimeOfArrivalCtrl.setValue(this.datetimeService.getTimeOfArrival(this.workingPoi.previousPoiEta,
                                                                                   estimatedDurationInSecond));
  }

  setMeasuredTimeOfArrival(measuredDurationInSecond) {
    this.measuredTimeOfArrivalCtrl.setValue(this.datetimeService.getTimeOfArrival(this.workingPoi.previousPoiEta,
                                                                                  measuredDurationInSecond));
  }

  /**
   *
   * @param _pace
   */
  setPaceFields(_pace) {

    const paceFull = this.convertorService.convertPaceFromStringToFullJson(_pace);

    if (typeof paceFull !== 'undefined') {
      this.estimatedAveragePaceMinCtrl.setValue(paceFull.minutes, {emitEvent: false});
      this.estimatedAveragePaceSecCtrl.setValue(paceFull.seconds, {emitEvent: false});
    }
  }

  /** Sauvegarde de la course
   *
   * Recupère la nouvelle entité Race
   *
   * @returns {TeardownLogic|AnonymousSubscription|Subscription}
   */
  saveEstimation() {

    if (this.poiForm.valid && this.estimationForm.valid) {

      super.savePoi();
      this.submitted = true;

      const action = (!this.workingPoi.trailerEstimation) ? 'addEstimation' : 'editEstimation';

      this.workingPoi = {...this.workingPoi, ...this.poiForm.value};

      const ret = {
        poi: this.workingPoi,
        action
      };

      // on refresh les Datas des segments intermédiaires
      this.dialogRef.close(ret);

      this.snackbarService.openSuccess('Estimation ajoutée avec succès');
    }

  }

  updateEstimatedDuration(estimatedDuration) {
    this.estimatedDurationCtrl.setValue(estimatedDuration);
  }

  updateMeasuredDuration(measuredDuration) {
    this.measuredDurationCtrl.setValue(measuredDuration);
  }

  deletePoiTrailer() {
    const ret = {
      poi: this.workingPoi,
      action: 'deleteEstimation'
    };
    this.dialogRef.close(ret);
    this.snackbarService.openSuccess('Estimation supprimé avec succès');
  }
}
