import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { RoadbookModel } from '../../models/roadbook.model';
import { SearchParam } from '../../classes/search-param';

import { Observable } from 'rxjs';
import { RoadbookPicture } from '../../models/roadbook-picture';
import { RoadbookPassportModel } from '../../models/roadbook-passport';

import * as FileSaver from 'file-saver';
import { UserModel } from '../../models/user.model';
import { TrailerModel } from '../../models/trailer.model';
import { SearchRoadbookCriterias } from '../../classes/searchRoadbookCriterias';
import { SearchService } from '../common/search.service';
import { RaceModel } from '../../models/race.model';
import { map } from 'rxjs/operators';
import { SpinnerService } from '../common/spinner.service';
import { FileService } from '../common/file.service';


@Injectable({
              providedIn: 'root'
            })
export class RoadbookService {

  roadbook: Observable<RoadbookModel>;

  _currentRoadbook: RoadbookModel;

  listRoadbooks: Observable<Array<RoadbookModel>>;

  roadbookUrl = '/api/roadbooks/';
  listRoadbooksUrl = '/api/roadbooks';
  addRoadbookUrl = '/api/roadbooks';

  getRoadbookPicturesFromRoadbookUrl = '/api/roadbook_pictures/';
  deleteRoadbookPictureUrl = '/api/roadbook_pictures/';

  private searchParams: Array<SearchParam> = [];
  private searchRoadbookCriterias: SearchRoadbookCriterias;

  constructor(private http: HttpService, private searchService: SearchService) {
  }

  /** Récupération de la liste complète des événements
   *
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  getRoadbooks(searchParams: Array<SearchParam>) {

    this.searchParams = searchParams;
    this.listRoadbooks = this.http.get(this.listRoadbooksUrl, this.searchParams);
    return this.listRoadbooks;
  }

  /** Retourne les détails d'une course
   *
   * @returns {Observable<RoadbookModel>}
   */
  getOneRoadbook(roadbookId: number): Observable<RoadbookModel> {
    this.roadbook = this.http.get<RoadbookModel>(this.roadbookUrl + roadbookId, null).pipe(
      map((roadbook: RoadbookModel) => {
        return new RoadbookModel(roadbook);
      }));
    return this.roadbook;
  }

  /** POST event
   *
   * @param event
   * @returns {Q.Promise<ErrorObservable>|Promise<ErrorObservable>|Promise<T>|Observable<R>|Promise<R>|any}
   */
  postRoadbook(roadbook: RoadbookModel): Observable<RoadbookModel> {
    return this.http.post(this.addRoadbookUrl, roadbook);
  }

  putRoadbook(roadbook: RoadbookModel): Observable<RoadbookModel> {
    return this.http.put(this.roadbookUrl + roadbook.id, roadbook);
  }

  patchRoadbookPassport(roadbookPassport: RoadbookPassportModel): Observable<RoadbookModel> {
    return this.http.patch(this.roadbookUrl + roadbookPassport.roadbookId + '/passport', roadbookPassport);
  }

  generateChartInPng(roadbookId: number, chartInBase64ImageJson: any) {
    return this.http.post(this.roadbookUrl + roadbookId + '/chart_in_png', chartInBase64ImageJson);
  }

  deleteRoadbook(roadbook: RoadbookModel) {
    return this.http.delete(this.roadbookUrl + roadbook.id, roadbook);
  }

  searchByName(term: string): Observable<Array<RoadbookModel>> {
    this.searchParams.push(new SearchParam('name', term));
    this.listRoadbooks = this.http.get(this.listRoadbooksUrl, this.searchParams);
    return this.listRoadbooks;
  }

  public getRoadbookPictures(roadbookId: number): Observable<Array<RoadbookPicture>> {
    return this.http.get(this.getRoadbookPicturesFromRoadbookUrl + roadbookId + '/by/roadbook', null);
  }

  /**
   *
   * @param roadbookId
   * @param generatePdf - demande génération du PDF
   * @returns {*}
   */
  public getRoadbookPdfFile(roadbookId: number, generatePdf: boolean = false): Observable<any> {
    return this.http.getBlob(this.roadbookUrl + roadbookId + '/pdf', [new SearchParam('generatePdf', generatePdf)]);
  }

  public downloadRoadbookPdf(data: any, filename: string) {
    const blob = new Blob([data], {type: 'application/pdf'});
    FileSaver.saveAs(blob, filename);
  }

  public previewRoadbookPdf(data: any) {
    const blob = new Blob([data], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  public deleteRoadbookPicture(roadbookPictureId: number) {
    return this.http.delete(this.deleteRoadbookPictureUrl + roadbookPictureId, null);
  }

  public get currentRoadbook(): RoadbookModel {

    let currentRoadbook: RoadbookModel;

    if (typeof this._currentRoadbook !== 'undefined') {
      currentRoadbook = this._currentRoadbook;
    } else if (typeof window.localStorage.getItem('current-roadbook') !== 'undefined') {
      currentRoadbook = JSON.parse(window.localStorage.getItem('current-roadbook'));
    }
    return currentRoadbook;
  }

  public set currentRoadbook(roadbook: RoadbookModel) {
    window.localStorage.setItem('current-roadbook', JSON.stringify(roadbook));
    this._currentRoadbook = roadbook;
  }

  getArchivedRoadbooks(user: UserModel, limit?: number) {
    this.searchParams = [];
    this.searchParams.push(new SearchParam('userId', user.id));
    this.searchParams.push(new SearchParam('status', 'archived'));
    this.searchParams.push(new SearchParam('createdOrderedBy', 'DESC'));
    return this.getRoadbooks(this.searchParams);
  }

  getOngoingRoadbooks(trailer: TrailerModel, scope?: string, limit?: number, offset?: number) {

    this.searchParams = [];
    this.searchParams.push(new SearchParam('trailer', trailer));

    if (limit) { this.searchParams.push(new SearchParam('limit', limit)); }
    if (offset) { this.searchParams.push(new SearchParam('offset', offset)); }
    if (status) { this.searchParams.push(new SearchParam('status', status)); }

    this.searchParams.push(new SearchParam('createdOrderedBy', 'DESC'));

    return this.getRoadbooks(this.searchParams);
  }

  getRoadbooksByScope(searchScope: string, loggedInUser: UserModel = null, limit: number = null) {
    this.setSearchRoadbookCriteriasByScope(searchScope, loggedInUser, limit);
    return this.searchService.searchElementByCriterias('roadbook', this.searchRoadbookCriterias);
  }


  /**
   *
   * @param searchScope|
   * @param loggedInUser
   * @param limit
   */
  setSearchRoadbookCriteriasByScope(searchScope: string, loggedInUser: UserModel, limit: number) {

    this.searchRoadbookCriterias = new SearchRoadbookCriterias();

    switch (searchScope) {
      case 'ongoing':
        this.searchRoadbookCriterias.status = ['complete', 'draft','validated'];
        this.searchRoadbookCriterias.limit = (limit) ? limit : null;
        break;
      case 'validated':
        this.searchRoadbookCriterias.status = ['validated'];
        break;
      case 'archived':
        this.searchRoadbookCriterias.status = ['archived'];
        break;
      case 'draft':
        this.searchRoadbookCriterias.limit = (limit) ? limit : null;
        this.searchRoadbookCriterias.status = ['draft'];
        break;
      case 'complete' :
        this.searchRoadbookCriterias.limit = (limit) ? limit : null;
        this.searchRoadbookCriterias.status = ['complete'];
        // tous les statuts mais classé par published et ensuite archivée
        break;
      default:
        break;
    }
  }

  /** Calcul du pourcentage de précision de l'trailerEstimation
   *
   * @param estimatedDuration
   * @param measuredDuration
   */
  calculateAccurateEstimationPercentage(estimatedDuration, measuredDuration) {
    let accuratePercent = 0;
    if (estimatedDuration && measuredDuration) {
      // let accuratePercent = Math.ceil(100 - Math.abs(100 - (estimatedDuration * 100 / measuredDuration)));
      // accuratePercent = Math.ceil(100 - (Math.abs(measuredDuration - estimatedDuration) * 100 / estimatedDuration));

      accuratePercent = Math.ceil(100 * (1 - (Math.abs(estimatedDuration - measuredDuration) / measuredDuration)));

      if (accuratePercent < 0) {
        accuratePercent = 0;
      }
    }

    return accuratePercent;
  }
}
