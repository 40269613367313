import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TerrainModel } from "../../../models/terrain.model";
import { ChallengeModel } from "../../../models/challenge.model";
import { DisciplineModel } from "../../../models/discipline.model";
import { RaceModel } from "../../../models/race.model";
import { SearchService } from "../../../services/common/search.service";
import { UserService } from "../../../services/user/user.service";
import { BaseComponent } from "../../base.component";
import { RaceService } from "../../../services/race/race.service";
import { HttpService } from "../../../services/http/http.service";
import { SpinnerService } from "../../../services/common/spinner.service";
import { SnackbarService } from "../../../services/notifications/snackbar.service";
import { DataService } from "../../../services/common/data.service";
import { OverlayService } from "../../../services/common/overlay.service";
import { CommonService } from "../../../services/common/common.service";
import { KEY_CODE } from "../../../enums/keyboard.enum";
import { DatetimeService } from "../../../services/common/datetime.service";
import { EventModel } from "../../../models/event.model";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { forkJoin, Observable, Subscription } from "rxjs";
import { RaceTagModel } from "../../../models/race-tag.model";
import { Moment } from "moment";
import { ErrorService } from "../../../services/common/error.service";
import { Options } from "@angular-slider/ngx-slider";
import { SearchEventCriterias } from "../../../classes/searchEventCriterias";
import { UtmbIndexEnum } from "../../../models/utmb-index.model";
import { GoogleMapService } from "../../../services/common/google-map.service";

@Component({
  selector: "search-box-advanced",
  templateUrl: "./search-box-advanced.component.html",
  styleUrls: ["./search-box-advanced.component.scss"],
})
export class SearchBoxAdvancedComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  _show = false;

  isThematicAccordionOpen = false;

  searchEventCriterias: SearchEventCriterias;

  resultRaces: Array<RaceModel> = [];
  resultEvents: Array<EventModel> = [];
  hasError = false;
  errorMessage: string;

  today: Date = new Date();

  searchRaceForm: UntypedFormGroup;

  range: UntypedFormGroup;

  distanceRange: UntypedFormGroup;
  ascendingElevationRange: UntypedFormGroup;

  nameCtrl: UntypedFormControl;
  locationCtrl: UntypedFormControl;
  inputAddressCtrl: UntypedFormControl;
  distanceRangeCtrl: UntypedFormControl;
  ascendingElevationRangeCtrl: UntypedFormControl;
  proximityCtrl: UntypedFormControl;
  maxPriceCtrl: UntypedFormControl;
  startCtrl: UntypedFormControl;
  dateSearchStartTimestampCtrl: UntypedFormControl;
  endCtrl: UntypedFormControl;
  dateSearchEndTimestampCtrl: UntypedFormControl;
  latCtrl: UntypedFormControl;
  lonCtrl: UntypedFormControl;
  localityCtrl: UntypedFormControl;
  postalCodeCtrl: UntypedFormControl;
  countryCtrl: UntypedFormControl;
  administrativeAreaLevel2Ctrl: UntypedFormControl;
  administrativeAreaLevel1Ctrl: UntypedFormControl;
  challengeCtrl: UntypedFormControl;
  terrainCtrl: UntypedFormControl;
  disciplineCtrl: UntypedFormControl;
  raceTagsCtrl: UntypedFormControl;
  hasTimeLimitCtrl: UntypedFormControl;
  isNightRaceCtrl: UntypedFormControl;
  arePolesProhibitedCtrl: UntypedFormControl;
  utmbIndexCtrl: UntypedFormControl;
  runningStonesCtrl: UntypedFormControl;

  //    Liste déroulantes
  raceTags: Array<RaceTagModel>;
  terrains: Array<TerrainModel>;
  challenges: Array<ChallengeModel>;
  disciplines: Array<DisciplineModel>;
  checkedTagIds: Array<number> = [];

  utmbIndexes = UtmbIndexEnum;
  utmbIndexKeys: Array<string> = [];

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() racesFound: EventEmitter<Array<RaceModel>> = new EventEmitter<
    Array<RaceModel>
  >();
  @Output() eventsFound: EventEmitter<Array<EventModel>> = new EventEmitter<
    Array<EventModel>
  >();
  @Output() eventsSearching: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild(HTMLInputElement) locationAutocomplete!: HTMLInputElement;
  public subPlaceChange!: Subscription;

  configSliderDistance: Options = {
    floor: 1,
    ceil: 1000,
    step: 5,
    logScale: true,
    noSwitching: true,
    minRange: 10,
  };
  public configSliderElevation = {
    floor: 50,
    ceil: 10000,
    step: 100,
    logScale: true,
    noSwitching: true,
    minRange: 200,
  };
  public configSliderProximity = {
    floor: 0,
    ceil: 500,
    step: 25,
    showSelectionBar: true,
  };
  public configSliderPrice = {
    floor: 0,
    ceil: 1500,
    step: 100,
    showSelectionBar: true,
  };
  public configSliderRunningStones = {
    floor: 0,
    ceil: 8,
    step: 1,
    showSelectionBar: true,
  };

  @ViewChild("searchWrap", { static: true }) private searchWrap: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    protected userService: UserService,
    private searchService: SearchService,
    private commonService: CommonService,
    private dataService: DataService,
    private errorService: ErrorService,
    private spinner: SpinnerService,
    private snackbarService: SnackbarService,
    private datetimeService: DatetimeService,
    public viewContainerRef: ViewContainerRef,
    private googleMapService: GoogleMapService
  ) {
    super(userService, viewContainerRef);

    this.nameCtrl = new UntypedFormControl("");
    this.locationCtrl = new UntypedFormControl("");
    this.inputAddressCtrl = new UntypedFormControl("");
    this.distanceRangeCtrl = new UntypedFormControl([
      this.configSliderDistance.floor,
      this.configSliderDistance.ceil,
    ]);
    this.ascendingElevationRangeCtrl = new UntypedFormControl([
      this.configSliderElevation.floor,
      this.configSliderElevation.ceil,
    ]);
    this.proximityCtrl = new UntypedFormControl(null);
    this.maxPriceCtrl = new UntypedFormControl(null);
    this.startCtrl = new UntypedFormControl("");
    this.dateSearchStartTimestampCtrl = new UntypedFormControl("");
    this.endCtrl = new UntypedFormControl("");
    this.dateSearchEndTimestampCtrl = new UntypedFormControl("");
    this.latCtrl = new UntypedFormControl("");
    this.lonCtrl = new UntypedFormControl("");
    this.localityCtrl = new UntypedFormControl("");
    this.postalCodeCtrl = new UntypedFormControl("");
    this.countryCtrl = new UntypedFormControl("");
    this.administrativeAreaLevel2Ctrl = new UntypedFormControl("");
    this.administrativeAreaLevel1Ctrl = new UntypedFormControl("");
    this.challengeCtrl = new UntypedFormControl(null);
    this.terrainCtrl = new UntypedFormControl(null);
    this.disciplineCtrl = new UntypedFormControl(null);
    this.raceTagsCtrl = new UntypedFormControl(null);
    this.hasTimeLimitCtrl = new UntypedFormControl(false);
    this.isNightRaceCtrl = new UntypedFormControl(false);
    this.arePolesProhibitedCtrl = new UntypedFormControl(false);
    this.utmbIndexCtrl = new UntypedFormControl("");
    this.runningStonesCtrl = new UntypedFormControl("");

    this.range = new UntypedFormGroup({
      start: this.startCtrl,
      end: this.endCtrl,
    });

    this.searchRaceForm = this.formBuilder.group({
      name: this.nameCtrl,
      inputAddress: this.inputAddressCtrl,
      distanceRange: this.distanceRangeCtrl,
      ascendingElevationRange: this.ascendingElevationRangeCtrl,
      proximity: this.proximityCtrl,
      maxPrice: this.maxPriceCtrl,
      latitude: this.latCtrl,
      longitude: this.lonCtrl,
      locality: this.localityCtrl,
      postalCode: this.postalCodeCtrl,
      country: this.countryCtrl,
      administrativeAreaLevel2: this.administrativeAreaLevel2Ctrl,
      administrativeAreaLevel1: this.administrativeAreaLevel1Ctrl,
      dateSearchStartTimestamp: this.dateSearchStartTimestampCtrl,
      dateSearchEndTimestamp: this.dateSearchEndTimestampCtrl,
      terrain: this.terrainCtrl,
      challenge: this.challengeCtrl,
      raceTags: this.raceTagsCtrl,
      discipline: this.disciplineCtrl,
      hasTimeLimit: this.hasTimeLimitCtrl,
      isNightRace: this.isNightRaceCtrl,
      arePolesProhibited: this.arePolesProhibitedCtrl,
      utmbIndex: this.utmbIndexCtrl,
      runningStones: this.runningStonesCtrl,
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.handleLocationChanges();

    this.searchEventCriterias = new SearchEventCriterias(this.loggedInUser);
    // remplissage des lists déroulantes
    this.populateDropDownLists();

    // Valeurs par défaut des sliders
    // this.initRangeSliders();
  }

  afterInit() {
    // nothing to do
  }

  ngAfterViewInit(): void {
    this.range.valueChanges.subscribe((range) => {
      if (range.start && range.end) {
        const start: Moment = range.start;
        const end: Moment = range.end;
        // on transforme les dates au format timestamp pour la recherche
        this.dateSearchStartTimestampCtrl.setValue(
          start.toDate()
            ? this.datetimeService.getTimestampInSecond(start.toDate())
            : null
        );
        this.dateSearchEndTimestampCtrl.setValue(
          end.toDate()
            ? this.datetimeService.getTimestampInSecond(end.toDate())
            : null
        );
      }
    });
  }

  private handleLocationChanges() {
    this.subPlaceChange = this.googleMapService.placeObservable
      .pipe(
        filter(
          (res) =>
            res.refererComponent === "full" || res.refererComponent === "lite"
        ),
        map((res) => res.place),
        tap((place) => {
          this.locationCtrl.setValue(
            this.googleMapService.getFormattedAddress(place)
          );
          this.latCtrl.setValue(this.googleMapService.getLatitude(place));
          this.lonCtrl.setValue(this.googleMapService.getLongitude(place));
          this.localityCtrl.setValue(this.googleMapService.getCity(place));
          this.postalCodeCtrl.setValue(
            this.googleMapService.getPostCode(place)
          );
          this.countryCtrl.setValue(this.googleMapService.getCountry(place));
          this.administrativeAreaLevel1Ctrl.setValue(
            this.googleMapService.getState(place)
          );
          this.administrativeAreaLevel2Ctrl.setValue(
            this.googleMapService.getDistrict(place)
          );
        })
      )
      .subscribe();
  }

  /**
   *
   * @param eventId
   */
  populateDropDownLists() {
    this.utmbIndexKeys = Object.keys(this.utmbIndexes);

    const observablDropDownList = [];

    const dropdownListsToLoad = [
      "raceTags",
      "terrains",
      "challenges",
      "disciplines",
    ];

    dropdownListsToLoad.forEach((listName: string) => {
      observablDropDownList.push(
        this.dataService.getDropDownList(listName).pipe(
          map((items) => {
            return { listName, items };
          })
        )
      );
    });

    forkJoin(observablDropDownList).subscribe(
      (listsKeyValues: any) => {
        listsKeyValues.forEach((listKeyValue) => {
          switch (listKeyValue.listName) {
            case "raceTags":
              this.raceTags = listKeyValue.items;
              break;
            case "terrains":
              this.terrains = listKeyValue.items;
              break;
            case "challenges":
              this.challenges = listKeyValue.items;
              break;
            case "disciplines":
              this.disciplines = listKeyValue.items;
              break;
          }
        });
      },
      (error) => {
        this.hasError = true;
        this.errorService.handleError(error);
      }
    );
  }

  /**
   * Soumettre la recherche
   */
  applySearch() {
    this.eventsSearching.emit(true);

    this.searchEventCriterias = this.searchRaceForm.value;
    this.searchEventCriterias.status = "published";
    this.searchEventCriterias.hasPublishedRaces = true;

    this.searchService
      .searchElementByCriterias("event", this.searchEventCriterias)
      .pipe(finalize(() => this.spinner.stop()))
      .subscribe({
        next: (events) => {
          this.resultEvents = events;
          this.searchWrap.nativeElement.scrollTop = 0;
          this.hide();
          this.eventsFound.emit(this.resultEvents);
        },
        error: () => {
          this.hasError = true;
          this.snackbarService.openDanger(
            "Erreur lors de la recherche des courses",
            "OK"
          );
        },
      });
  }

  resetLocationCtrl() {
    this.localityCtrl.reset();
    this.countryCtrl.reset();
    this.postalCodeCtrl.reset();
    this.administrativeAreaLevel2Ctrl.reset();
    this.administrativeAreaLevel1Ctrl.reset();
  }

  resetFilters() {
    this.distanceRange.reset({ distanceRange: [50, 200] });
    this.ascendingElevationRange.reset({
      ascendingElevationRange: [1000, 4000],
    });
    // TODO reset autres filtres
  }

  clearFilters() {
    this.searchRaceForm.reset();
    this.distanceRangeCtrl.setValue([
      this.configSliderDistance.floor,
      this.configSliderDistance.ceil,
    ]);
    this.ascendingElevationRangeCtrl.setValue([
      this.configSliderElevation.floor,
      this.configSliderElevation.ceil,
    ]);
    this.maxPriceCtrl.setValue(0);
    this.maxPriceCtrl.setValue(null);
    this.proximityCtrl.setValue(null);
  }

  toggleShow() {
    if (this._show) {
      this._show = false;
    } else {
      this._show = true;
    }
    this.commonService.scroll(!this._show);
  }

  show() {
    this._show = true;
  }

  hide() {
    this._show = false;
    // déblocage du scroll du body
    this.commonService.scroll(true);
  }

  /** Gestion des touches claviers
   *
   * @param event
   */
  @HostListener("document:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.hide();
    }
  }

  onTagSelectedChange(e) {
    const raceTagId = +e.target.value;

    if (e.target.checked) {
      this.checkedTagIds.push(raceTagId);
    } else {
      this.checkedTagIds.forEach((checkedTagId, index) => {
        if (checkedTagId === raceTagId) {
          this.checkedTagIds.splice(index, 1);
        }
      });
    }
    this.raceTagsCtrl.setValue(this.checkedTagIds);
  }
}
